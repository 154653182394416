.App {
  text-align: center;
  /* height: var(--env-cdn,100vh); */
  width: var(--env-cdn, 100vw);
  width:fit-content;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: var(--env-cdn-app-header, 100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal-content-style{
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .24), 0 0 8px 0 rgba(0, 0, 0, .12);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.popup {
  width: var(--popup-width, 475px);
  /* height: var(--popup-height, auto); */
  margin: auto;
}

@media (max-width: 475px) {
  .popup {
    width: 95%;
    min-width: 300px;
  }
}

.mid-popup{
  width: 375px;
  margin: auto;
}

@media (max-width: 375px) {
  .mid-popup {
    width: 95%;
    min-width: 300px;
  }
}

.small-popup {
  width:315px;
  margin: auto;
}

@media (max-width: 315px) {
  .small-popup {
    width: 95%;
    min-width: 300px;
  }
}

.upload-title-text{
  font-size: var(--dynamic-font-size-title, 20px);
  font-weight: var(--dynamic-font-weight-title, 600);
  color: var(--dynamic-color, #000);
  font-family: var(--dynamic-font-family);
}

.upload-content-text{
  font-size: var(--dynamic-font-size-content, 18px);
  font-weight: var(--dynamic-font-weight-content, 400);
  color: var(--dynamic-color, #000);
  font-family: var(--dynamic-font-family);
}

.upload-info-text{
  font-size: var(--dynamic-font-size-info, 16px);
  font-weight: var(--dynamic-font-weight-info, 400);
  color: var(--dynamic-color, #000);
  font-family: var(--dynamic-font-family);
}

.upload-footer-text{
  font-size: 13px;
  font-weight: 600;
  color: var(--dynamic-color, #000);
}

.upload-button{
  background-color: var(--dynamic-color, #000);
  color: #fff;
  font-family: "Montserrat", sans-serif;  
  /* padding: 8px 30px; */
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px;
  border: 0;
  border-color: var(--dynamic-color, #000) white white white;
}

/* Only show border when dynamic color is white */
.upload-button:is([style*="--dynamic-color:#fff"], [style*="--dynamic-color:white"]) {
  border: 2px solid #fff;
}

.widget-App-styles{
  min-width: 300px;
    min-height: 300px;
    margin: auto;
    display: flex;
}